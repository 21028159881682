import React from "react";
import { useAuth } from "@helpers/hooks/useAuth";

import NavbarSupplier from "@components/common/Navbar/NavbarSupplier";
import NavbarUser from "@components/common/Navbar/NavbarUser";
import NavbarGuest from "@components/common/Navbar/NavbarGuest";
import { useRouter } from "next/router";
import NavbarSimple from "@components/common/Navbar/NavbarSimple";

const Navbar = () => {
  const { auth } = useAuth();
  const router = useRouter();

  // Add NavbarSimple for /auth/bookings/{id}/thanks
  const showSimpleNavbar =
    router.pathname.startsWith("/auth/bookings/") &&
    router.pathname.includes("/thanks");

  if (showSimpleNavbar) {
    return <NavbarSimple showPreferenceIfUserAuth={true} />;
  }

  if (auth) {
    if (auth.isSupplier) {
      return <NavbarSupplier />;
    }

    return <NavbarUser />;
  }

  return <NavbarGuest />;
};

export default Navbar;

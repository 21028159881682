import React, { useEffect, useState } from "react";
import { Trans, t } from "@helpers/translate";
import Link from "next/link";
import { useRouter } from "next/router";
import InputDestination from "../components/InputDestination";
import Preferences from "../components/Preferences";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
import { Navbar } from "../../../../components/ui/Navbar";
import { ROUTES } from "@root/routes";

const NavbarGuest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const router = useRouter();

  const { query } = router;

  useEffect(() => {
    if (!document) {
      return;
    }
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <Navbar fixed={true}>
      <Navbar.Search onClick={() => setIsSearchOpen(!isSearchOpen)} />
      <Navbar.Brand>
        {!router.pathname.startsWith("/support") && (
          <div className="md:mx-4">
            <InputDestination
              isOpen={isSearchOpen}
              setIsOpen={setIsSearchOpen}
            />
          </div>
        )}
      </Navbar.Brand>
      <Navbar.Menu onClick={() => setIsOpen(!isOpen)} />
      <Navbar.Right>
        <Navbar.Nav>
          <Navbar.NavItem>
            <Link
              href={getAbsoluteUrl(ROUTES.contact())}
              title={t({
                id: "common.link.title",
                message: "GoWithGuide - Private Tours & Local Tour Guides",
              })}
            >
              <Trans id="navbar.help">Help</Trans>
            </Link>
          </Navbar.NavItem>
          <Navbar.NavItem className="hover:no-underline">
            <Navbar.Button
              href={getAbsoluteUrl(
                query.supplier ? "/login?supplier=1" : "/login"
              )}
            >
              <Trans id="navbar.login">Login</Trans>
            </Navbar.Button>
          </Navbar.NavItem>
        </Navbar.Nav>
      </Navbar.Right>
      {isOpen && (
        <Navbar.MenuLayer isOpen={isOpen} onClick={() => setIsOpen(false)}>
          <Navbar.Button
            href={getAbsoluteUrl(
              query.supplier ? "/login?supplier=1" : "/login"
            )}
            className="block mb-8"
          >
            <Trans id="navbar.login">Login</Trans>
          </Navbar.Button>
          <Navbar.MenuItem href={getAbsoluteUrl(ROUTES.about())}>
            <Trans id="navbar.about">About Us</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem href={getAbsoluteUrl(ROUTES.press())}>
            <Trans id="navbar.press">Press</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem href={getAbsoluteUrl(ROUTES.blog.index())}>
            <Trans id="navbar.blog">Blog</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem href={getAbsoluteUrl(ROUTES.faq())}>
            <Trans id="navbar.faq">FAQ</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem href={getAbsoluteUrl(ROUTES.contact())}>
            <Trans id="navbar.contact">Contact Us</Trans>
          </Navbar.MenuItem>
          <Navbar.MenuItem>
            <Preferences />
          </Navbar.MenuItem>
        </Navbar.MenuLayer>
      )}
    </Navbar>
  );
};

export default NavbarGuest;
